<script lang="ts">
	import ArticleCard from "$components/ArticleCard.svelte";
	import { onMount } from 'svelte';
	import { swipe } from 'svelte-gestures';
	import { CarouselHelper } from '$lib/helpers/swipe.helper';
	import type { Article } from '$lib/domain/article.interface';
	import type { Blog } from '$lib/domain/blog.interface';
	export let articles: (Article | Blog)[] = [];
	export let showArticleChapter: true| false = true;
	export let showArticleTag: true| false = true;

	export let titleAlign: 'start' | 'center' | 'end' = 'center';
	export let title: string | "" = 'Insights from Our Experience';

	let activeIndex = 0;
	const maxCount = articles.length;
	const carouselHelper = CarouselHelper.getInstance(maxCount);

	onMount(() => {
			if (!window.matchMedia('(max-width: 1400px)').matches) {
					return;
			}

			carouselHelper.resetInterval();
			carouselHelper.indexStore.subscribe((index) => (activeIndex = Number(index)));
	});
</script>

<div class="container">
	<div class="section-title" style="align-items: {titleAlign}">
		<h2>{@html title}</h2>
	</div>
	
	<div class="services">
		{#each articles as article}
			<a href="{article.url}">
				<ArticleCard article="{article}" showChapter="{showArticleChapter}" showTag="{showArticleTag}" categoryTag="{article.tags[0]}"/>
			</a>
		{/each}
	</div>
	
	<div
		class="carousel"
		use:swipe="{{ timeframe: 300, minSwipeDistance: 60, touchAction: 'pan-y' }}"
		on:swipe="{carouselHelper.handleSwipe}">
		{#each articles as article, i}
			<div class="carousel-item" class:active="{activeIndex === i}">
				<a href="{article.url}">
					<ArticleCard article="{article}" showChapter="{showArticleChapter}" showTag="{showArticleTag}"
											 categoryTag="{article.tags[0]}"/>
				</a>
			</div>
		{/each}
	</div>
	
	<ol class="carousel-indicators">
		{#each Array(maxCount) as _, i}
			<li
				on:click="{() => carouselHelper.select(i)}"
				on:keydown="{() => carouselHelper.select(i)}"
				class:active="{i === activeIndex}">
				<div class="carousel-bullet">•</div>
			</li>
		{/each}
	</ol>
</div>

<style lang="scss">
	@import '$styles/vars';

	.services {
		display: flex;
		flex-wrap: wrap;
		gap: 24px;
		margin-top: 48px;
		color: $main-black;

		a {
			flex-basis: 30%;
			flex-grow: 1;
		}


	}

	.carousel,
	.carousel-indicators {
		display: none;
	}

	h2 {
		color: $main-black;

	}

	@media (min-width: $portrait-phone-size) and (max-width: $tablet-size) {
		.services {
			gap: 12px;
			a {
				flex-basis: 40%;
			}
		}
	}

	@media (max-width: $portrait-phone-size) {
		.services {
			display: none;
		}

		.carousel,
		.carousel-indicators {
			display: flex;
		}
	}
</style>
